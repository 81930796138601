<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="pickingBinModal"
    :title="$t(type) + ' ' + $t('Picking Bins')"
    :buttons-hidden="true" autocomplete="nofill">
    <div class="sm:w-1/2 w-full">
      <vs-input v-validate="'required'" :label-placeholder="$t('ID')" name="id" v-model="bin.id" :class="bin.id ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
      <span class="text-danger text-xs" v-show="errors.has('id')">{{ errors.first('id') }}</span>
      <span class="text-danger text-xs" v-if="!errors.has('id')">{{ bin.id && (bin.id[0] !== 'P' || bin.id[1] !== 'B') ? $t('First and second letters must be PB capital') : bin.id && (bin.id.length === 2 || /\D/.test(bin.id.slice(2))) ? $t('This field must have numeric data after first character') : '' }}</span>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button v-if="type === 'add'" @click="addPickingBin()" class="btn active-btn">{{ $t('Add') }}</button>
      <button v-if="type === 'edit'" @click="updatePickingBins" class="btn active-btn">{{ $t('Save') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import { sendFulfillmentRequest } from '../../../http/axios/requestHelper'
import i18nData from '../../../i18n/i18nData.js'

export default {
  props: ['pickingBinModal', 'type', 'binsEditData'],
  components: {
    ShipbluPrompt,
    vSelect
  },
  data () {
    return {
      bin: {}
    }
  },
  watch: {
    'pickingBinModal' (val) {
      if (this.type === 'edit' && val === true) {
        this.bin.id = this.binsEditData.ops_id
      }
    }
  },
  methods: {
    addPickingBin () {
      this.$validator.resume()
      this.$validator.validateAll().then(result => {
        if (result && this.bin.id[0] === 'P') {
          let data = {}
          data = {
            ops_id: this.bin.id
          }
          this.sendData(data)
        }
      })
    },
    sendData (data) {
      data = {
        ...data
      }
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/picking-bins/', 'post', data, true,
        () => {
          this.closeModal()
          this.$emit('loadPickingBins')
        }
      )
    },
    updatePickingBins () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const pickingBinsData = {
            ops_id: this.bin.id
          }
          sendFulfillmentRequest(false, false, this, `api/v1/fc/picking-bins/${this.binsEditData.id}/`, 'patch', pickingBinsData, true,
            () => {
              this.closeModal()
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Product'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
            }
          )
        }
      })  
    },
    closeModal () {
      this.$validator.pause()
      this.bin = {}
      this.$emit('loadPickingBins')
      this.$emit('pickingBinModal', false)
    }
  }
}
</script>