<template>
  <div class="shipments-page">
    <div class="flex items-center justify-between">
      <h2>{{$t('Picking Bins')}}</h2>
      <vs-button v-if="this.$store.state.AppActiveUser.userRole === 'fc-operations-supervisor'" @click="openPickingBinsModal()" color="primary" icon-pack="feather" icon="icon-plus" size="large"></vs-button>
    </div>
    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :tableLoader="tableLoader"
      :data="pickingBins"
    >
      <template slot="thead">
        <shipblu-th>{{$t('ID')}}</shipblu-th>
        <shipblu-th>{{$t('Picking Tasklist ID')}}</shipblu-th>
        <shipblu-th>{{$t('No. Products')}}</shipblu-th>
        <shipblu-th>{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].id">
            {{ data[indextr].ops_id }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].picking_tasklist_id">
            {{ data[indextr].picking_tasklist_id}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].numberOfProducts">
            {{ data[indextr].no_of_items }}
          </shipblu-td>

          <shipblu-td>
            <div @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                class="dd-actions cursor-pointer"
                @click="selected = []"
              >
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-more-horizontal"
                >
                </vs-button>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    :disabled ="$store.state.AppActiveUser.userRole !== 'fc-operations-supervisor'"
                    @click="editBin(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit Bin')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    :disabled ="$store.state.AppActiveUser.userRole !== 'fc-operations-supervisor'"
                    @click="deleteBin(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete Bin')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    :disabled ="$store.state.AppActiveUser.userRole !== 'fc-operations-supervisor'"
                    @click="printQrCode(data[indextr])"
                  >
                  <span class="flex items-center">
                    <feather-icon
                      icon="PrinterIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Print QR Code')}}</span>
                  </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <qr-code-storage-units v-if="this.$store.state.AppActiveUser.userRole === 'fc-operations-supervisor'" :storageUnit="pickingBin"></qr-code-storage-units>
    <add-picking-bins v-if="this.$store.state.AppActiveUser.userRole === 'fc-operations-supervisor'" :pickingBinModal="pickingBinModal" @loadPickingBins="loadPickingBins" @pickingBinModal="pickingBinModal = $event" :type="type" :binsEditData="binsEditData"></add-picking-bins>
  </div>
</template>

<script>
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import common  from '@/assets/utils/common'
import i18nData from '../../i18n/i18nData.js'
import {sendFulfillmentRequest} from '../../http/axios/requestHelper.js'
import addPickingBins from './components/AddPickingBins.vue'
import QrCodeStorageUnits from '../fcOperationsSupervisor/components/QrCodeStorageUnits.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    addPickingBins,
    QrCodeStorageUnits,
    ShipbluPagination
  },
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      storageModal: false,
      totalRows: 0,
      selected: [],
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      offset: 0,
      pickingBins: [],
      searchInProgress: false,
      searchedValue: ' ',
      deleteData:{},
      pickingBinModal: false,
      pickingBin: {},
      type: '',
      tableLoader: false,
      binsEditData: {}
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadPickingBins()
    }
  },
  methods: {
    removeAttribute (event) {
      event.preventDefault()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadPickingBinsSearch)
    },
    loadPickingBins () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/picking-bins/${query}`, 'get', null, true,
        (response) => {
          this.pickingBins = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    openPickingBinsModal () {
      this.pickingBinModal = true
      this.type = 'add'
    },
    loadPickingBinsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadPickingBins()
    },
    deleteBin (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/picking-bins/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Bin'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadPickingBins()
        }
      )
    },
    editBin (data) {
      this.type = 'edit'
      this.pickingBinModal = true
      this.binsEditData = data
    },
    printQrCode (bin) {
      this.pickingBin = bin
      setTimeout(() => {
        window.print()
      }, 300)
    }
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadPickingBins()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>